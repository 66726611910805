<template>
	<v-container
		fluid
		fill-height
		class="pa-0 background_gray"
	>
		<!-- STEP01. 비회원확인 -->
		<v-col
			v-if="GUEST_GET_MYPAGE_INFO === 0"
			cols="12"
			class="pa-0"
			align="center"
		>
			<v-col
				cols="12"
				class="pa-0"
				align="center"
			>
				<HomeInteriorMypageConfirm01
					v-if="GUEST_GET_MYPAGE_INFO === 0"
					@nextLevel="nextLevel"
				/>
			</v-col>
		</v-col>

		<!-- STEP02. 신청 리스트 -->
		<v-col
			v-if="GUEST_GET_MYPAGE_INFO === 1"
			cols="12"
			class="pa-0"
			align="center"
		>
			<v-col
				cols="12"
				class="pa-0"
				align="center"
			>
				<HomeInteriorMypageConfirm02
					v-if="GUEST_GET_MYPAGE_INFO === 1"
					:userdata="getDataFromSessionStorage"
				/>
			</v-col>
		</v-col>

		<!-- STEP03. 신청 리스트 디테일 -->
		<v-col
			v-if="GUEST_GET_MYPAGE_INFO === 2"
			cols="12"
			class="pa-0"
			align="start"
			style="align-self: start"
		>
			<v-col
				cols="12"
				class="pa-0"
				align="start"
			>
				<HomeInteriorMypageConfirm03 v-if="GUEST_GET_MYPAGE_INFO === 2" />
			</v-col>
		</v-col>

		<!-- <CommonDialog
			:dialog="dialog.dialog"
			:icon="dialog.icon"
			:title="dialog.title"
			:text="dialog.text"
			@close="closeDialog()"
		/> -->
	</v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import common from '@/mixins/common'

export default {
	name: 'MypageGuest',
	metaInfo: {
		title: '비회원 마이페이지 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '비회원 마이페이지 | 동화자연마루',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '동화자연마루 비회원 마이페이지',
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	mixins: [common],
	data: () => ({
		session_loginId: null,
		session_loginName: null,
	}),
	computed: {
		...mapGetters([
			'APP_GET_DIALOG',
			'APP_GET_LOADING',
			'AUTH_GET_USER',
			'AUTH_GET_USER_AUTH',
			'APP_GET_APP_STATE',
			'GUEST_GET_MYPAGE_INFO',
		]),

		tabItems() {
			if (this.AUTH_GET_USER_AUTH === 'USER') return this.customTypeItems.user
			else if (this.AUTH_GET_USER_AUTH === 'AGENCY') return this.customTypeItems.agency
			return []
		},
		userTypeCols() {
			if (this.AUTH_GET_USER_AUTH === 'USER') {
				return this.APP_GET_APP_STATE !== 'web' ? 4 : 2
			} else if (this.AUTH_GET_USER_AUTH === 'AGENCY') {
				return this.APP_GET_APP_STATE !== 'web' ? 6 : 3
			}
		},
		getTab() {
			return Number(this.$route.params.tab) || 0
		},
		getQuery() {
			return Number(this.$route.query.tab) || 0
		},
		loading() {
			return this.APP_GET_LOADING
		},
		dialog() {
			return !this.APP_GET_DIALOG ? { dialog: false, icon: '', title: '', text: '' } : this.APP_GET_DIALOG
		},
		getDataFromSessionStorage() {
			// 세션 스토리지에서 데이터 가져오기
			return JSON.parse(sessionStorage.getItem('user_data'))
		},
		getMypageInfo() {
			return this.GUEST_GET_MYPAGE_INFO
		},
	},
	watch: {
		AUTH_GET_USER_AUTH() {
			this.checkAuthGetData()
		},
		customType() {
			this.$router.push({ path: '/user', query: { tab: this.customType } }).catch(() => {})
		},
		getQuery() {
			this.changeTab(this.getQuery)
		},
		getMypageInfo(newval) {
			if (newval === 1) {
				window.onpopstate = null
			}
			if (newval === 2) {
				this.scrollTop()
			}
		},
	},
	created() {
		// if (this.AUTH_GET_USER_AUTH === 'USER' || this.AUTH_GET_USER_AUTH === 'AGENCY') {
		// 	alert('??')
		// 	this.$router.push('/')
		// }
		if (this.GUEST_GET_MYPAGE_INFO === 1) {
			window.onpopstate = null
		}
	},
	mounted() {
		this.checkAuthGetData()
		this.customType = this.getTab
		if (this.getQuery !== 0) this.customType = this.getQuery
	},
	methods: {
		...mapActions([
			// user
			'USER_ACT_QUALITY',
			'USER_ACT_AS',
			'USER_ACT_QUESTION',
			'USER_ACT_ESTIMATE',

			// agency
			'AGENCY_ACT_HOUSEWARMING',
			'AGENCY_ACT_ESTIMATE',
			'AGENCY_ACT_ASK',

			'GUEST_ACT_ESTIMATE',
		]),
		...mapMutations(['GUEST_MU_MYPAGE_INFO', 'APP_MU_DIALOG']),
		async checkAuthGetData() {
			// if (this.AUTH_GET_USER_AUTH === 'USER') this.$router.push('/')
			// else if (this.AUTH_GET_USER_AUTH === 'AGENCY') this.callAgencyInfo()
			// else if (this.AUTH_GET_USER_AUTH === 'GUEST') return this.callAgencyInfo()

			if (this.AUTH_GET_USER_AUTH === 'USER' || this.AUTH_GET_USER_AUTH === 'AGENCY') {
				// this.APP_MU_DIALOG({
				// 	dialog: true,
				// 	title: '비회원 마이페이지',
				// 	text: '비회원전용 견적상담페이지 입니다. 마이페이지를 이용해 주세요.',
				// })
				return this.$router.push('/user?tab=2')
			}

			if (this.getDataFromSessionStorage) {
				await this.checkAlreadyLogin()
			}
		},
		async callUserInfo() {
			// 견적상담
			await this.USER_ACT_ESTIMATE({
				method: 'get',
				params: {
					login_id: this.AUTH_GET_USER.login_id,
				},
			})
		},
		async checkAlreadyLogin() {
			await this.GUEST_ACT_ESTIMATE({
				method: 'get',
				params: {
					login_name: this.getDataFromSessionStorage.login_name,
					login_id: this.getDataFromSessionStorage.login_id,
				},
			})
				.then(res => {
					if (res) {
						this.GUEST_MU_MYPAGE_INFO(1)
					}
				})
				.catch(e => {
					console.log('checkNoUserData', e)
				})
		},
		// async checkNoUserData() {
		// 	await this.GUEST_ACT_ESTIMATE({
		// 		method: 'get',
		// 		params: {
		// 			login_name: this.session_loginId,
		// 			login_id: this.session_loginName,
		// 		},
		// 	})
		// 		.then(res => {
		// 			if (res) {
		// 				this.saveToSessionStorage()
		// 			}
		// 		})
		// 		.catch(e => {
		// 			console.log('checkNoUserData', e)
		// 		})

		// 	// let items = {
		// 	// 	method: 'post',
		// 	// 	params: {
		// 	// 		memberYN: this.AUTH_GET_USER.login_id ? 'Y' : 'N',
		// 	// 		login_id: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.hand,
		// 	// 		login_name: this.AUTH_GET_USER.name ? this.AUTH_GET_USER.name : this.name,
		// 	// 		regi_userid: this.AUTH_GET_USER.login_id ? this.AUTH_GET_USER.login_id : this.name,
		// 	// 	},
		// 	// }
		// },
		nextLevel(level) {
			this.GUEST_MU_MYPAGE_INFO(level)
		},
		changeTab(tab) {
			this.customType = tab
		},
		scrollTop() {
			window.scrollTo(0, 0)
		},
	},
}
</script>

<style scoped lang="scss"></style>
